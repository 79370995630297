// Navbar.tsx
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import "./Navbar.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const isMobile = width < 768;

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <nav className="navbar">
      <NavLink className="navbar-logo" to="/">
        <div className="logo-text">
          <img src="/ownlogo.png" width={160} />
        </div>
      </NavLink>
      {!isMobile && (
        <ul className="navbar-menu">
          <li className="active">
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/our-vision">Our Vision</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
      )}
    {isMobile && (
      <div className="navbar-hamburger">
        <input type="checkbox" id="menu-toggle" onChange={(e)=>setIsMenuOpen(e?.currentTarget?.checked)} checked={isMenuOpen} />
        <label htmlFor="menu-toggle" className="menu-icon">
          <span className="navicon"></span>
        </label>
        <ul className="navbar-menu">
          <li onClick={()=>setIsMenuOpen(false)}>
            <NavLink to="/">Home</NavLink>
          </li>
          <li onClick={()=>setIsMenuOpen(false)}>
            <NavLink to="/our-vision">Our Vision</NavLink>
          </li>
          <li onClick={()=>setIsMenuOpen(false)}>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
      </div>
    )}
    </nav>
  );
};

export default Navbar;
