import { Outlet } from 'react-router-dom';

import NavBar from "../core/navbar";
import Footer from '../core/Footer';

const MainLayout = () => {
    return (
        <>  
            <NavBar/>
            <Outlet />
            <Footer />
        </>
    )
}

export default MainLayout;