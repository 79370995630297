import {
  Box,
  Typography,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useState, useRef, useMemo, useEffect } from "react";
import { getRandomColor } from "../../helper/util";
import { upsertUser } from "../../helper/ApiClient";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";

function capitalizeFirstLetter(str: String) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
}

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const VendorProfile = ({ vendorData }: any) => {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const steps = ["Your Details", "Verify OTP", "Vendor Information"];

  const reSetForm = () => {
    setUserDetails({
      name: "",
      email: "",
      phone: "",
    });
    setOtp(["", "", "", "", "", ""]);
    setActiveStep(0);
    setOtpVerified(false);
    setLoading(false);
    window.recaptchaVerifier.clear();
    configureCaptcha();
  };

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log(response);
        },
        "expired-callback": () => {
          console.log("Response expired. Ask user to solve reCAPTCHA again.");
        },
        defaultCountry: "IN",
      }
    );
  };

  useEffect(() => {
    configureCaptcha();
  }, []);

  const avatarColor = useMemo(() => getRandomColor(), []);

  const handleCustomer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reSetForm();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOtpChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleNext = async () => {
    setLoading(true);
    if (activeStep === 0) {
      const phoneNumber = userDetails.phone;
      const appVerifier = window.recaptchaVerifier;

      try {
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          "+91" + phoneNumber,
          appVerifier
        );
        window.confirmationResult = confirmationResult;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error("Error sending OTP:", error);
      } finally {
        setLoading(false);
      }
    } else if (activeStep === 1) {
      const code = otp.join("");
      try {
        const result = await window.confirmationResult.confirm(code);
        await upsertUser(
          userDetails.phone,
          userDetails.email,
          userDetails.name
        );
        setOtpVerified(true);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error("Error verifying OTP or upserting user:", error);
      } finally {
        setLoading(false);
      }
    } else {
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        marginRight: "20px",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: "20px",
        }}
      >
        <Avatar
          alt={vendorData?.companyName}
          src={vendorData?.profilePictureUrl || "profile.jpg"}
          sx={{
            width: 90,
            height: 90,
            marginBottom: "20px",
            backgroundColor: avatarColor,
            color: "white",
            fontSize: "2rem",
          }}
        />
        <Typography>{vendorData?.companyName}</Typography>
        <Typography>
          {capitalizeFirstLetter(vendorData?.vendorType)} Designer
        </Typography>
        <Typography>
          {vendorData?.city}, {vendorData?.state}
        </Typography>
        <Button
          onClick={handleCustomer}
          sx={{ marginTop: "10px" }}
          variant="contained"
        >
          Contact Vendor
        </Button>
      </Box>

      <Dialog open={open} disableEscapeKeyDown>
        <DialogTitle>Provide Your Details to Connect with Vendor</DialogTitle>
        <DialogContent>
          <Stepper
            activeStep={
              steps.length - 1 === activeStep ? activeStep + 1 : activeStep
            }
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Full Name"
                type="text"
                fullWidth
                variant="standard"
                value={userDetails.name}
                onChange={handleChange}
                disabled={loading}
              />
              <TextField
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                value={userDetails.email}
                onChange={handleChange}
                disabled={loading}
              />
              <TextField
                margin="dense"
                name="phone"
                label="Phone"
                type="tel"
                fullWidth
                variant="standard"
                value={userDetails.phone}
                onChange={handleChange}
                disabled={loading}
              />
            </>
          )}
          {activeStep === 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderRadius: "8px",
                margin: "20% auto",
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
              >
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    inputRef={(ref) => (inputRefs.current[index] = ref)}
                    value={digit}
                    onChange={(e) =>
                      handleOtpChange(e.target.value.slice(0, 1), index)
                    }
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleKeyDown(e, index)
                    }
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: "center", fontSize: "24px" },
                    }}
                    type="text"
                    sx={{ width: "40px", margin: "0 5px" }}
                    disabled={loading}
                  />
                ))}
              </Box>
            </Box>
          )}
          {activeStep === 2 && otpVerified && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderRadius: "8px",
                backgroundColor: "#f5f5f5",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                marginTop: "20px",
              }}
            >
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Vendor Details
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                <strong>Email:</strong> {vendorData?.email}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                <strong>Phone:</strong> {vendorData?.mobileNumber}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                <strong>Address:</strong> {vendorData?.address}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {vendorData?.googleUrl && (
                  <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                    <IconButton
                      component="a"
                      href={vendorData.googleUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GoogleIcon />
                    </IconButton>
                  </Typography>
                )}
                {vendorData?.instagramUrl && (
                  <Typography variant="body1" sx={{ marginBottom: "5px" }}>
                    <IconButton
                      component="a"
                      href={vendorData.instagramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </IconButton>
                  </Typography>
                )}
                {vendorData?.facebookUrl && (
                  <Typography variant="body1">
                    <IconButton
                      component="a"
                      href={vendorData.facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon />
                    </IconButton>
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {activeStep !== steps.length - 1 && (
            <Button onClick={handleClose} color="secondary" disabled={loading}>
              Cancel
            </Button>
          )}
          <Button
            onClick={handleNext}
            color="primary"
            disabled={loading}
            sx={{
              backgroundColor:
                activeStep === steps.length - 1 ? "green" : "primary",
              color: activeStep === steps.length - 1 ? "white" : "green",
              "&:hover": {
                backgroundColor:
                  activeStep === steps.length - 1 ? "darkgreen" : "primary",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : activeStep === steps.length - 1 ? (
              "Finish"
            ) : (
              "Next"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <div id="recaptcha-container"></div>
    </Box>
  );
};

export default VendorProfile;
