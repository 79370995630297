import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getProjectsImgs } from "../helper/ApiClient";
import { Container } from "@mui/material";
import Masonry from "react-responsive-masonry";
import useWindowDimensions from "../hooks/useWindowDimensions";

const ProjectPage = () => {
  const { id, vendor, name } = useParams<{
    id: string;
    vendor: string;
    name: string;
  }>();
  const [images, setImages] = useState<any[]>([]);

  const { width } = useWindowDimensions();

  const getColumnsCount = () => {
    if (width >= 1024) return 3;
    if (width >= 768) return 2;
    return 1;
  };

  useEffect(() => {
    async function fetchImages() {
      try {
        if (id && vendor) {
          const response = await getProjectsImgs(id, vendor);

          const formatedImgList = response?.map((img: any) => ({
            src: img.gcsUrl,
            ...img,
          }));

          setImages(formatedImgList);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    }

    fetchImages();
  }, [id, vendor]);

  return (
    <Container
      maxWidth={"lg"}
      disableGutters
      sx={{
        padding: "1rem",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <h1>{name?.toLocaleUpperCase()}</h1>
        <div>
          <Masonry
            columnsCount={getColumnsCount()}
            className="my-masonry-grid"
            gutter="10px"
          >
            {images.map((image, i) => (
              <Link to={`/vendor/${vendor}`} key={i}>
                <img
                  src={image?.src}
                  style={{ width: "100%", display: "block" }}
                  alt={`image-${i}`}
                />
              </Link>
            ))}
          </Masonry>
        </div>
      </div>
    </Container>
  );
};

export default ProjectPage;
