import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { getVendor, getProjectsByVendor } from "../helper/ApiClient";
import { useParams } from "react-router-dom";
import VendorProfile from "../components/vendor/VendorProfile";
import VendorProjects from "../components/vendor/VendorProjects";

const Broker = () => {
  const [vendorData, setVendorData] = useState<any>(null);
  const [projects, setProjects] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  // const [searchParams]: any = useSearchParams();
  // console.log({ type: searchParams["type"], vendorData });

  async function fetchVendor(id: string) {
    try {
      const vendorResponse = await getVendor(id);
      setVendorData(vendorResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchProjects(id: string) {
    try {
      const projectsResponse = await getProjectsByVendor(id);
      setProjects(projectsResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchVendor(id);
      fetchProjects(id);
    }
  }, [id]);

  return (
    <Box
      sx={{
        padding: "20px",
        minHeight: "75vh",
        position: "relative",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <VendorProfile vendorData={vendorData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <VendorProjects projects={projects} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Broker;
