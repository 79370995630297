import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';

// Reusable Section Component
const Section: React.FC<{
  title: string;
  content: React.ReactNode;
}> = ({ title, content }) => (
  <Box sx={{ backgroundColor: '#ffffff', padding: 3, boxShadow: 1, borderRadius: 2, textAlign: 'center' }}>
    <Typography variant="h5" fontWeight="bold" marginBottom={2}>
      {title}
    </Typography>
    {content}
  </Box>
);

// Form Field Component
const FormField: React.FC<{
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
}> = ({ label, name, value, onChange, type = "text", required = false, multiline = false, rows = 1 }) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      type={type}
      required={required}
      multiline={multiline}
      rows={rows}
    />
  </Grid>
);

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
  };

  return (
    <Box component="section" sx={{ padding: 4, backgroundColor: '#f9f9f9' }}>
      {/* Page Header Section */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Typography variant="h3" gutterBottom fontWeight="bold">
          Contact Us
        </Typography>
        <Typography variant="h6" color="textSecondary" paragraph>
          Ready to Design Your Dream Home?
        </Typography>
        <Typography variant="body1" color="textSecondary" maxWidth="700px" margin="0 auto">
          We’re here to bring your vision to life with personalized interior design solutions. Whether you’re starting from scratch or need a refresh, our expert designers are ready to help!
        </Typography>
      </Box>

      {/* Contact Information and Contact Form in the same row */}
      <Grid container spacing={4} justifyContent="center">
        {/* Contact Information Section */}
        <Grid item xs={12} md={5}>
          <Section
            title="Contact Us"
            content={
              <>
                <Typography variant="body1" marginBottom={1}>📞 Call Us: +91 7708 002 230</Typography>
                <Typography variant="body1" marginBottom={1}>📧 Email Us: Nikinragav@gmail.com</Typography>
                <Typography variant="body1">📍 Visit Us: No 18, 19 Palaniappa nagar, near Chil Sez IT Park Road, Saravanampatti, Coimbatore, Tamil Nadu 641035</Typography>
              </>
            }
          />
        </Grid>

        {/* Contact Form Section */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 4, boxShadow: 3, borderRadius: 2, backgroundColor: '#ffffff' }}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3} textAlign="center">
              Contact Us Form
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <FormField label="Full Name" name="name" value={formData.name} onChange={handleChange} required />
                <FormField label="Email Address" name="email" value={formData.email} onChange={handleChange} type="email" required />
                <FormField label="Phone Number" name="phone" value={formData.phone} onChange={handleChange} type="tel" />
                <FormField label="Message" name="message" value={formData.message} onChange={handleChange} multiline rows={4} required />
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth size="large" sx={{ fontWeight: 'bold', padding: '10px 0' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
