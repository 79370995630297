import { Box, Grid2, Typography } from "@mui/material";
import style from "./scss/RoomDesigns.module.scss";
import { useEffect, useState } from "react";
import { getRoomTypes } from "../../helper/ApiClient";

interface RoomType {
  tagId: number;
  tagName: string;
  tagTypeId: number;
  imageUrl: string;
}

const RoomDesigns = () => {
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);

  useEffect(() => {
    async function fetchRoomTypes() {
      try {
        const response = await getRoomTypes();
        setRoomTypes(response);
      } catch (error) {
        console.error("Error fetching room types:", error);
      }
    }

    fetchRoomTypes();
  }, []);

  return (
    <Box
      sx={{
        margin: 5,
        marginBottom: { xs: 15, md: 5 },
      }}
    >
      <Grid2 container spacing={3}>
        {roomTypes.map((room) => {
          return (
            <Grid2 size={{ xs: 12, md: 3 }} key={room?.tagId}>
              <a
                href={`/room-design/${room.tagName}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className={style.imageHolder}
                >
                  <img src={room?.imageUrl} alt="LivingRoom" width={"100%"} />
                  <Typography
                    sx={{
                      position: "relative",
                      bottom: 35,
                      zIndex: 9,
                    }}
                  >
                    {room?.tagName}
                  </Typography>
                </Box>
              </a>
            </Grid2>
          );
        })}
      </Grid2>
    </Box>
  );
};

export default RoomDesigns;
