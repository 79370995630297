// App.tsx
import React from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from "./routes"
import { ThemeProvider } from "@mui/material";
import appTheme from './theme';
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={appTheme}>
        <div className="App">
          <Routes />
        </div>
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
