import { Box, Typography, Container, Grid, Icon } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BrushIcon from "@mui/icons-material/Brush";
import BuildIcon from "@mui/icons-material/Build";
import DevicesIcon from "@mui/icons-material/Devices";
import StarIcon from "@mui/icons-material/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const OurVision: React.FC = () => {
  return (
    <Box component="section">
      <Box
        sx={{
          padding: 4,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h2" component="h1" gutterBottom>
              About Us
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              Creating Identity Through Design
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              At ownhouse.ai, we believe every home is a unique canvas—a
              reflection of its owner’s personality, tastes, and dreams. We’re
              here to celebrate individuality with bespoke, tailor-made designs
              that capture your personal essence.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "#e0e0e0",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Your Home, Your Identity
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              Your home is more than just a space—it’s an emotional sanctuary
              that tells your story. We’re dedicated to transforming every room
              into a bespoke haven that resonates with your personal identity
              and style. Whether you lean towards modern minimalism, classic
              elegance, or vibrant eclecticism, we tailor our designs to echo
              your inner world.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Why We’re Different
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              In a market dominated by giant, mass-manufacturing interior design
              companies, ownhouse.ai stands out by offering:
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={HomeIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Personalized Experiences
                </Typography>
                <Typography variant="body1" align="center">
                  We take the time to understand your vision, emotions, and
                  lifestyle, crafting design solutions that are uniquely
                  yours—not just another off-the-shelf template.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={BrushIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Attention to Detail
                </Typography>
                <Typography variant="body1" align="center">
                  Every detail matters. Our network of skilled interior
                  designers listens carefully to your needs and pays meticulous
                  attention to every element, ensuring that each design detail
                  reflects your personality.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={BuildIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Customization Over Conformity
                </Typography>
                <Typography variant="body1" align="center">
                  Unlike mass-produced designs, our approach emphasizes true
                  customization. We work closely with you to create spaces that
                  are tailor-made, celebrating individuality rather than
                  conformity.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={DevicesIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Technology-Driven Design
                </Typography>
                <Typography variant="body1" align="center">
                  Embracing the future of interior design, ownhouse.ai leverages
                  cutting-edge AI and top-notch technology to streamline your
                  design journey. Our advanced algorithms analyze your style
                  preferences and offer personalized recommendations, while
                  interactive virtual experiences allow you to visualize your
                  dream space before it comes to life.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={StarIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Quality Craftsmanship
                </Typography>
                <Typography variant="body1" align="center">
                  By focusing on quality over quantity, we deliver designs that
                  are crafted with passion, precision, and care—ensuring that
                  your home isn’t just aesthetically pleasing, but also built to
                  stand the test of time.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Icon component={AccessTimeIcon} sx={{ fontSize: 40, mb: 2 }} />
                <Typography variant="h5" component="h3" gutterBottom>
                  Effortless Discovery & Time Savings
                </Typography>
                <Typography variant="body1" align="center">
                  With just a scroll of your screen, explore a carefully curated
                  collection of interior designs that save you time and
                  eliminate the hassle of endless searching. Our marketplace
                  brings together the best of interior design in one convenient
                  platform, allowing you to instantly access inspiration that’s
                  right for you.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "#e0e0e0",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h5" component="h3" gutterBottom>
              Curated for Convenience
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              Our platform aggregates exceptional design ideas, handpicked by
              top interior designers. This means you can quickly discover and
              refine your style without sifting through countless options,
              leaving you more time to enjoy the process of making your home
              truly yours.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Our Mission
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              Our mission is simple: to empower homeowners with design
              identities that truly represent who they are. We bridge the gap
              between inspiration and execution, connecting you with expert
              interior designers who bring a personal touch to every project.
              With ownhouse.ai, you’re not just choosing a design—you’re
              embracing a lifestyle that values individuality and heartfelt
              craftsmanship.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          padding: 4,
          backgroundColor: "#e0e0e0",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Join the Movement
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{ maxWidth: "800px", mb: 4 }}
            >
              Step away from the ordinary and join a community that celebrates
              personal expression through design. Explore our curated
              collections, connect with passionate designers, and start the
              journey to transform your home into a unique expression of your
              identity. Because at ownhouse.ai, we believe that great design
              begins with you.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default OurVision;
