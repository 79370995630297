import { Box, Grid, Typography } from "@mui/material";
import style from "../home/scss/RoomDesigns.module.scss";
import { Link } from "react-router-dom";

const VendorProjects = ({ projects }: any) => {
  return (
    <Box sx={{ flex: 2 }}>
      <Typography variant="h3" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={2}>
        {projects.map((project: any) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={project.id}
            className={style.imageHolder}
          >
            <Link
              to={`/project/${project?.project?.projectId}/${project?.project?.vendorId}/${project?.project?.projectName}`}
              style={{ textDecoration: "none" }}
            >
              <img
                src={project?.thumbnails?.[0].gcsUrl}
                alt={project?.project?.projectName}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  position: "relative",
                  bottom: 35,
                  zIndex: 9,
                  color: "black",
                }}
              >
                {project?.project?.projectName}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VendorProjects;
