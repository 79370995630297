import { get, post } from "./ApiHelper";

export async function getRoomTypes(): Promise<any> {
  const url = `home/images`;
  return get<any>(url);
}
export async function getVendor(id: string): Promise<any> {
  const url = `vendor/${id}`;
  return get<any>(url);
}
export async function getProjectsByVendor(id: string): Promise<any> {
  const url = `projects/byVendorID/${id}`;
  return get<any>(url);
}
export async function getProjectsImgs(
  id: string,
  vendorId: string
): Promise<any> {
  const url = `projects/images/byId?vendorId=${vendorId}&projectId=${id}`;
  return get<any>(url);
}

export async function getRoomTypeImages(roomDesign: string): Promise<any> {
  const url = `images/byTag/${roomDesign}`;
  return get<any>(url);
}

export async function upsertUser(
  mobileNumber: string,
  email: string,
  name: string
): Promise<any> {
  const url = `user/upsert`;
  const data = {
    mobileNumber,
    email,
    name,
  };
  return post<any>(url, data);
}
