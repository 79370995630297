import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import {green } from '@mui/material/colors';

const CTAVendor: React.FC = () => {
    return (
        <Box sx={{ backgroundColor: green[200], py: 4 }}>
            <Container maxWidth="md" sx={{ textAlign: 'center' }}>
                <Typography variant="h4" component="h2" gutterBottom>
                    Partner with Us – Become a Vendor!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Join our platform to expand your reach and grow your business with a larger audience.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="http://vendor.ownhouse.ai"
                    target='_blank'
                >
                    Get Started
                </Button>
            </Container>
        </Box>
    );
}

export default CTAVendor;