import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

interface TokenDetails {
  token: string;
}

export const getToken = (): string | undefined => {
  const tokenDetailsString = localStorage.getItem("userDetails");
  if (!tokenDetailsString) return undefined;

  const tokenDetails: TokenDetails = JSON.parse(tokenDetailsString);
  return tokenDetails?.token;
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getToken();

  config.headers = config.headers || {};
  if (token && token !== "") {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
